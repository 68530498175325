import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { GatsbyImage } from 'gatsby-plugin-image'

const PageComponent = ({ data }) => {
    const { strapiProjectReferencesPages: pageData } = data
    const __html = pageData.fields?.markdownBody?.childMarkdownRemark.html
    const backgroundImage =
        pageData.BackgroundImage?.localFile?.childImageSharp.gatsbyImageData
    const featureImage =
        pageData.Image?.localFile?.childImageSharp.gatsbyImageData
    return (
        <Layout backgroundImage={backgroundImage}>
            <Seo title={pageData.Title} />
            <div className='df grid md50 project-references-page'>
                <div>
                    <GatsbyImage
                        image={featureImage}
                        alt={pageData.ImageCaption}
                    />
                </div>
                <div className='md' dangerouslySetInnerHTML={{ __html }} />
            </div>
        </Layout>
    )
}

export default PageComponent
export const pageQuery = graphql`
    query ProjectReferencesPages($id: String!) {
        strapiProjectReferencesPages(id: { eq: $id }) {
            Title
            BackgroundImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            Image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, width: 400)
                    }
                }
            }
            ImageCaption
            fields {
                markdownBody {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`
